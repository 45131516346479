.app-header {
  height: 61px;
  background-color: #ff9200;
  border-bottom: none;
  z-index: 1000 !important;
  padding: 0;

  .navbar-nav {
    .worker-label {
      width: 35px !important;
    }
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 100px;

    i.fas {
      font-size: 24px;
      padding-top: 7px;
      color: #dcdcdc;
    }
  }

  .nav-item {
    text-align: center;
  }
}

@media (max-width: 991.98px) {
  .app-header {
    height: 49px;
  }
}

.navbar > .nav-menu {
  height: 100%;
  max-width: 950px;
  opacity: 1;
  transition: opacity 125ms;
  background: #ff9200;

  > .nav-item {
    max-width: 118px;
  }
}

.nav-menu {
  .nav-item {
    position: relative;

    .nav-menu {
      display: none;
    }

    &.active {
      .nav-menu {
        display: none !important;
      }
    }

    &:hover {
      >.nav-link {
        background-color: #FFB34C;
        color: #7F5926;
      }

      .nav-menu {
        display: flex;
      }
    }

    .nav-link.active {
      background-color: #FFB34C;
      color: #7F5926;
    }

    .nav-link {
      display: flex;
      cursor: pointer;
      padding: 20px 0;
      color: #fff;
      text-decoration: none;
      font-size: 1.1em;
      white-space: nowrap;
    }

    @media (min-width: 992px) {
      .nav-menu {
        position: absolute;
        top: 61px;
        left: 0;

        .nav-item {
          border-left: 1px solid #ff9200;
          border-right: 1px solid #ff9200;
          background-color: #ff9200;
          width: 100%;

          &:not(:last-child) {
            border-bottom: 1px solid #ff9200;
          }
          &:last-child {
            border-bottom: 1px solid #ff9200;
          }

          .nav-link {
            padding-left: 15px;
            padding-right: 15px;
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .navbar {
    > .nav-menu {
      height: calc(100% - 49px);
      position: fixed;
      //display: none;
      opacity: 0;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto;
      left: -9999px;
      top: -9999px;
      margin-left: 0 !important;
      width: 100%;

      .nav-item {
        max-width: 100%;
        text-align: left;
        .nav-link {
          padding: 12px 15px;
        }
      }
    }

    .nav-menu .nav-menu {
      display: block !important;

      .nav-item .nav-link {
        padding-left: 35px;
      }
    }

    .nav-item {
      background-color: #ff9200;
    }
  }

  .nav-menu-open {
    .navbar {
      > .nav-menu {
        //display: block;
        opacity: 1;
        left: 0;
        top: 49px;
      }
    }
  }
}
